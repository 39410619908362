.about {
  &__content {
    display: flex;
    gap: 20px;
    padding: 20px;
    width: 100%;

    &__personalWrapper,
    &__servicesWrapper {
      width: 50%;
    }

    &__personalWrapper {
      h3 {
        margin-bottom: 10px;
        font-size: 2.5rem;
        font-weight: 700;
        color: var(--yellow-theme-main-color);
      }

      h3.personalInformationHeaderText {
        padding-top: 20px;
      }

      p {
        color: var(--yellow-theme-sub-text-color);
        font-size: 1.8rem;
        line-height: 1.65;
        padding-bottom: 20px;
        border-bottom: 1px dashed var(--yellow-theme-main-color);
      }

      ul > li {
        line-height: 1rem;
        margin-bottom: 15px;

        span.title,
        span.value {
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 0.5px;
          border-bottom: 2px solid var(--yellow-theme-main-color);
          position: relative;
          display: inline-block;
          margin-right: 16px;
          padding-bottom: 5px;
          color: var(--yellow-theme-sub-text-color);
        }

        span.value {
          border: none;
        }
      }
    }

    &__servicesWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      &__innerContent {
        width: 350px;
        height: 350px;
        background: radial-gradient(
          180px,
          var(--yellow-theme-main-color),
          transparent 90%
        );
        position: relative;
        border-radius: 50%;
        border: 1.5px solid var(--yellow-theme-main-color);
        animation: rotation 10s linear 1s infinite;

        div:nth-child(1) {
          position: absolute;
          top: -9%;
          left: 41%;
        }

        div:nth-child(2) {
          position: absolute;
          right: -8%;
          top: 41.5%;
        }

        div:nth-child(3) {
          position: absolute;
          bottom: -10%;
          left: 41%;
        }

        div:nth-child(4) {
          position: absolute;
          left: -8%;
          top: 41%;
        }
      }
    }
  }
}

@keyframes rotation {
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width : 768px){
    .about__content{
        flex-direction: column;

        &__personalWrapper,
        &__servicesWrapper {
          width: 100%;
        }

        &__servicesWrapper{
            margin: 10px 0px;
            &__innerContent {
                width: 300px;
                height: 300px;
                background: radial-gradient(120px,var(--yellow-theme-main-color),transparent 120%);


                div:nth-child(1) {
                    position: absolute;
                    top: -15%;
                    left: 38%;
                  }
          
                  div:nth-child(2) {
                    position: absolute;
                    right: -11%;
                    top: 40%;
                  }
          
                  div:nth-child(3) {
                    position: absolute;
                    bottom: -12%;
                    left: 40%;
                  }
          
                  div:nth-child(4) {
                    position: absolute;
                    left: -12%;
                    top: 40%;
                  }
            }
        }
    }


}
